import React, {useEffect, useMemo, useState} from "react";
import {
    Box, 
    Card, 
    IconButton,
    Typography,
    Badge
} from "@mui/material";
import TicketPreview from "./TicketPreview";
import SearchInput from "./SearchInput";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Collapse from '@mui/material/Collapse';
import {useParams} from "react-router-dom";
import {STATUSES, PRIORITIES, getLabel, PriorityIcon, TYPES} from "../../KanbanBoard/options";
import createFilter from "./createFilter";
import FilterSelect from "./FilterSelect";
import {List,AutoSizer} from "react-virtualized";
import {useDispatch, useSelector} from "react-redux";
import {getUnreadMessages} from "../messengerApi";
import {setUnreadMessages} from "../../../store/ticketsSlice";

const rowRenderer =(sortedTickets,id)=> ({key, index, style})=>{
    return (
        <TicketPreview
            style={style}
            key={key}
            selected={sortedTickets[index].id==id}
            ticket={sortedTickets[index]}
        />)
};
export default function TicketsList({setOpenCreate,tickets=[],excludeTeams=[]}){
    const [openFilters,setOpenFilters] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const updateOrder = useSelector(state => state.ticketsReducer.forceOrderInList)
    const [filter,setFilter] = useState({
        search:'',
        priority:[],
        status:[],
        type:[]
    })
    const sortedTickets = useMemo(
        ()=>[...tickets].sort((t1,t2)=>((updateOrder[t2.id]*1000)||new Date(t2.updated_at  ) )
            - ((updateOrder[t1.id]*1000)||new Date(t1.updated_at ))
        )
        .filter(createFilter(excludeTeams,filter))
        ,[tickets,excludeTeams,filter,updateOrder]);
    const types = useSelector(state => state.optionsReducer.types);
    const typesKeys = useMemo(()=>Object.keys(types),[types]);
    const scrollToIndex = sortedTickets.findIndex(el=>el.id==id)||0;
    useEffect(() => {
        getUnreadMessages()
            .then((response)=>{
                dispatch(setUnreadMessages(response?.data?.data))
            })
            .catch(e=>{console.log()})
    }, []);
    return <Card sx={{pb:1,height:`calc(100vh - 180px)`,display:'flex',flexDirection:'column',minWidth:245}}>
        <Box p={3} >
            <Box display={'flex'} mb={3} gap={.5} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={18} fontWeight={700}>
                    Tickets
                </Typography>
                <Box sx={{flex:1}} />
                {/*<IconButton onClick={()=>setOpenCreate(true)}>*/}
                {/*    <AddIcon />*/}
                {/*</IconButton>*/}
                <Badge 
                    overlap="circular"
                    color="primary" 
                    variant="dot" 
                    invisible={(!filter.status.length&&!filter.priority.length&&!filter.type.length)}
                >
                    <IconButton onClick={()=>setOpenFilters(prevState => !prevState)}>
                        {openFilters?<ExpandLessRoundedIcon/>:<ExpandMoreRoundedIcon/>}
                    </IconButton>
                </Badge>
            </Box>
            <SearchInput
                filter={filter}
                setFilter={setFilter}
            />
            <Collapse in={openFilters}>
                <FilterSelect
                    name={'priority'}
                    options={PRIORITIES}
                    filter={filter}
                    setFilter={setFilter}
                    renderOptionFn={(option)=><><PriorityIcon priority={option} /> {getLabel(option)}</>}
                />
                <FilterSelect
                    name={'status'}
                    options={STATUSES}
                    filter={filter}
                    setFilter={setFilter}
                    renderOptionFn={(option)=>getLabel(option)}
                />
                <FilterSelect
                    name={'type'}
                    options={typesKeys}
                    filter={filter}
                    setFilter={setFilter}
                    renderOptionFn={(option)=>types[option]}
                />
            </Collapse>
        </Box>
        <Box sx={{
            overflowY:'auto',
            flexGrow:1
        }}>
            {/*<Box px={3} mb={1} display={'flex'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>*/}
            {/*    <PushPinRoundedIcon sx={{transform:'rotate(45deg)',color:'text.disabled',fontSize:18}} />*/}
            {/*    <Typography sx={{color:'text.disabled'}}>*/}
            {/*        Pinned Tickets*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*<TicketPreview priority={"low"} pinned />*/}
            {/*<Divider/>*/}
            <AutoSizer style={{
                width:'100%',
                height:'100%',
                overflow:'hidden'
            }}>
                {({height, width}) => (
                    <List
                        height={height}
                        rowCount={sortedTickets.length}
                        rowHeight={64}
                        style={{ width:'100%',}}
                        rowRenderer={rowRenderer(sortedTickets,id)}
                        width={width}
                        scrollToIndex={scrollToIndex}
                    />
                )}
            </AutoSizer>
        </Box>
    </Card>
}