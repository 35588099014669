import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {BASE_DOMAIN} from "./API";

window.Pusher = Pusher;

function setupSocket(bearerToken){
    window.Echo = new Echo({
        broadcaster: 'pusher',
        authEndpoint:`https://${BASE_DOMAIN}/broadcasting/auth`,
        key: 'app-key',
        wsHost: BASE_DOMAIN,
        wsPort: 6002,
        wssPort: 6002,
        forceTLS: true,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        cluster: "mt1",
        bearerToken
    });
}
export default setupSocket;