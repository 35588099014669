import React,{useMemo} from "react";
import {Grid,Box,Typography,Avatar,IconButton,Chip,alpha,Tooltip} from "@mui/material";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import {blue} from "@mui/material/colors";
import {Draggable} from "@hello-pangea/dnd";
import {getLabel} from "./options";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Link } from "react-router-dom";
import paths from "../../router/paths";
import {DAY_MS} from "./index";
import {useSelector} from "react-redux";

export function getStatusBg(theme,status,draggingOver){
    const currentStatus = draggingOver?draggingOver.split('-')[1]:status;
    
    switch (currentStatus){
        case 'todo':
            return theme.palette.primary.light;
        case 'in_process':
            return theme.palette.primary.main;
        case 'in_review':
            return theme.palette.warning.main;
        case 'done':
            return theme.palette.success.main;
        case 'declined':
            return theme.palette.error.main;
        default:
            return theme.palette.primary.main;
    }
}

export function getPriority(theme,priority,draggingOver){
    const currentPriority = draggingOver?draggingOver.split('-')[0]:priority;
    switch (currentPriority){
        case 'highest':
            return theme.palette.error.main;
        case 'high':
            return theme.palette.warning.main;
        case 'medium':
            return theme.palette.success.main;
        case 'low':
            return blue[600];
        default:
            return ''
    }
}

export const TicketCard = ({ticket,handleEditDialog,provided, snapshot})=>{
    const {
        due_date,
        description,
        status,
        priority,
        created_at,
        requester_name,
        requester_avatar,
        type
    }=ticket;
    const time_spent= useMemo(()=>{
        return  Math.floor(((new Date() - new Date(created_at))/DAY_MS)*100 )/100+'d';
    },[created_at]);
    const isExpired = useMemo(()=> new Date(due_date) < new Date(),[due_date]);
    const types = useSelector(state=> state.optionsReducer.types);
    return <Box 
        component={Link}
        sx={{color:'inherit'}} 
        to={paths.tickets.chat.path.replace(':id',ticket.id)}
    >
        <Box
            sx={(theme)=>({
                backgroundColor:theme.palette.mode==='dark'?theme.palette.grey[800]:"#fff",
                borderRadius:2,
                mb:2,
                maxWidth:185,
                cursor:'pointer !important',
                position:'relative',
                height:213.5,
                ...(snapshot.isDragging?{
                    boxShadow:theme.shadows[6],
                    border:'2px solid',
                    borderColor:getStatusBg(theme,status,snapshot.draggingOver),
                    "&:before":snapshot.draggingOver?.split('-')[1]==='declined'&&isExpired?{
                        content:'"Ticket is Expired!"',
                        position:'absolute',
                        inset:'0',
                        height:'100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:alpha(getStatusBg(theme,status,snapshot.draggingOver),0.65),
                    }:{}
                }:{}),
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <Grid container sx={(theme)=>({
                p:2,
                backgroundColor:alpha(getStatusBg(theme,status,snapshot.draggingOver),0.2),
                borderRadius:2,
                height: '100%',
            })} alignItems={'center'}>
                <Grid item xs={9}>
                    <Tooltip title={'Due date'}>
                        <Typography
                            sx={(theme)=>({
                                color:isExpired
                                    ?theme.palette.error.main
                                    :theme.palette.text.primary
                            })}
                            fontSize={13}
                        >
                            {due_date}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <IconButton
                        sx={{ml:'auto',display:'flex'}}
                        onClick={(e)=>{
                            e.preventDefault();
                            handleEditDialog(ticket)
                        }}
                    >
                        <EditRoundedIcon/>
                    </IconButton>
                </Grid>
                <Grid
                    item
                    sx={{
                        height:40,
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                    xs={12}
                >
                    <Tooltip title={'Type'}>
                        <Typography
                            variant={"h6"}
                            textAlign={'center'}
                            sx={{
                                overflow:'hidden',
                                maxWidth:'100%',
                                textOverflow:'ellipsis',
                                fontSize:14,
                                maxHeight:'100%',
                                WebkitLineClamp:3,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                                wordBreak:'break-word'
                            }}
                        >
                            {types[type]}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item sx={{py:.5,display:'flex',justifyContent:'flex-start',alignItems:'center'}} xs={12}>
                    <Typography
                        sx={{
                            overflow:'hidden',
                            maxWidth:'100%',
                            textOverflow:'ellipsis',
                            fontSize:10,
                            maxHeight:'100%',
                            WebkitLineClamp:3,
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                            wordBreak:'break-word'
                        }}
                    >
                        {description}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{fontSize:11}}>
                    Priority: {priority} <CircleRoundedIcon sx={(theme)=>({
                    fontSize:'inherit',
                    verticalAlign:'middle',
                    color:getPriority(theme,priority)
                })} />
                </Grid>
                {/*<Grid item xs={12} sx={{fontSize:11}}>*/}
                {/*    Type: {getLabel(type)} */}
                {/*</Grid>*/}
                <Grid item xs={6} mt={.5}>
                    <Tooltip title={`Time spent: ${time_spent}`}>
                        <Chip size={"small"} sx={{mt:.25,mb:.25}} label={time_spent} />
                    </Tooltip>
                </Grid>
                <Grid item xs={6} mt={.5}>
                    <Tooltip title={`Requester: ${requester_name}`}>
                        <Avatar
                            src={requester_avatar}
                            sx={{width: 28, height: 28,ml:'auto'}}
                            alt={requester_name}
                        >
                            {requester_name[0]}
                        </Avatar>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    </Box>
}

export default function BoardTicketCard({ticket,handleEditDialog,index}){
    return <Draggable draggableId={ticket.id.toString()} index={index}>
        {(provided, snapshot)=>(
            <TicketCard
                provided={provided}
                snapshot={snapshot}
                ticket={ticket}
                handleEditDialog={handleEditDialog}
            />
        )}
    </Draggable>
}