import React, {useEffect, useState} from "react";
import {Box, Grid, Card, Avatar, IconButton, TextField, Button, Typography,Alert} from "@mui/material";
import cover from "../../assets/sky-asset.jpeg";
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';
import {useSelector} from "react-redux";
import SmartToyIcon from '@mui/icons-material/SmartToy';

export default function Profile(){
    const user = useSelector(store=>store.userReducer.user);
    const [profileForm,setProfileForm] = useState({name:'',email:''});
    useEffect(()=>{
        if(user){
            setProfileForm({
                name:user.name||'',
                email:user.email||''
            });
        }
    },[user]);
    return <Box>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card 
                    sx={{
                        position:'relative'
                    }}
                >
                    <Box
                        sx={{
                            backgroundImage:`url(${cover})`,
                            backgroundSize:'cover',
                            backgroundPositionY:'bottom',
                            height:150
                        }}
                    />
                    <Box p={3}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            {/*<IconButton*/}
                            {/*    sx={theme=>({*/}
                            {/*        mt:-10,*/}
                            {/*        "&:hover .innerIcon": {*/}
                            {/*            opacity:.6,*/}
                            {/*        }*/}
                            {/*    })}*/}
                            {/*>*/}
                            {/*    <PhotoCameraRoundedIcon*/}
                            {/*        sx={theme=>({*/}
                            {/*            fontSize:50,*/}
                            {/*            position:'absolute',*/}
                            {/*            margin:'auto',*/}
                            {/*            zIndex:2,*/}
                            {/*            pointerEvents:'none',*/}
                            {/*            opacity:0,*/}
                            {/*            transition:theme.transitions.create('opacity',{duration:theme.transitions.duration.standard})*/}
                            {/*        })}*/}
                            {/*        className={'innerIcon'}*/}
                            {/*    />*/}
                            {/*    <Avatar*/}
                            {/*        sx={{*/}
                            {/*            height:90,width:90,*/}
                            {/*        }}*/}
                            {/*    >T</Avatar>*/}
                            {/*</IconButton>*/}
                            <Avatar
                                sx={{
                                    height:90,width:90,
                                    mt:-10,
                                }}
                            >
                                <SmartToyIcon fontSize={'large'} />
                            </Avatar>
                        </Box>
                        <Grid container spacing={2} sx={{maxWidth:500,mx:'auto',py:2}}>
                            <Grid item xs={12}>
                                <Typography sx={{textAlign:'center'}} pr={2}>
                                    ID {user.id}
                                </Typography>
                            </Grid>
                            {Notification.permission==='denied'&&<Grid item xs={12}>
                                <Alert severity="warning">
                                    Your browser notifications is blocked!<br/> 
                                    Enable them in settings to get alerts about new tickets and messages.
                                </Alert>
                            </Grid>}
                            <Grid item xs={12}>
                                <TextField 
                                    fullWidth 
                                    label={'Name'}
                                    value={profileForm.name}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <TextField 
                                    fullWidth
                                    label={'Email'}
                                    value={profileForm.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled fullWidth label={'Slack id'} value={user.slack_id||''} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled fullWidth label={'Slack team id'} value={user.slack_team_id||''} />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField fullWidth label={'New password'}  />*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField fullWidth label={'Confirm new password'} />*/}
                            {/*</Grid>*/}
                            <Grid item container justifyContent={'center'} xs={12}>
                                <Button disabled>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    </Box>
}