import React, {useMemo, useState} from "react";
import {Avatar, Chip, Grid, Typography, styled,Button} from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EditDialog from "../../KanbanBoard/EditDialog";
import {getTimeStringFromSeconds} from "../../../Utils";
import {useDispatch, useSelector} from "react-redux";
import {updateTicket} from "../../../store/ticketsSlice";
import StatusChip from "../StatusChip";
import {getLabel} from "../../KanbanBoard/options";

const PropRow = styled(Typography)({
    fontSize:12,
    wordBreak:'break-word'
});

export default function Details({ticket}){
    const [openEdit,setOpenEdit] = useState(false);
    const teams = useSelector(state=> state.optionsReducer.teams);
    const dispatch = useDispatch();
    const space = useMemo(()=>teams.find(el=> el.id===ticket.project_slack)
        ,[ticket.project_slack,teams]);
    const types = useSelector(state => state.optionsReducer.types)
    return <Grid container rowGap={1} >
        <Grid item xs={6}>
            <PropRow>
                <b>Start date:</b> {ticket.start_date||''}
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b>Time spent:</b>
                {ticket.time_spent
                    ?<Chip
                        sx={{height: 18}}
                        component={'span'}
                        label={getTimeStringFromSeconds(ticket.time_spent)}
                        size={'small'}
                        variant={'contained'}
                    />
                    :'-'
                }
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b>Created by:</b>
                <Avatar
                    component={"span"}
                    sx={{
                        display:'inline-flex',
                        height:18,
                        width:18,
                        fontSize:12,
                        mx:.5
                    }}
                    src={ticket.requester_avatar}
                >{ticket.requester_name&&ticket.requester_name[0]}</Avatar>
                {ticket.requester_name}
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b>Assignee:</b>
                <Avatar
                    component={"span"}
                    sx={{
                        display:'inline-flex',
                        height:18,
                        width:18,
                        fontSize:12,
                        mx:.5
                    }}
                    src={ticket.assignee_avatar}
                >{ticket.assignee_name&&ticket.assignee_name[0]}</Avatar>
                {ticket.assignee_name}
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b> Decline reason:</b> {ticket.decline_reason?getLabel(ticket.decline_reason):'-'}
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b>Slack space:</b>
                <Avatar 
                    src={space?.icon}
                    component={"span"}    
                    sx={{
                        display:'inline-flex',
                        height:18,
                        width:18,
                        fontSize:12,
                        mx:.5
                    }} 
                    variant="rounded"
                />
                    {space?.name}
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b>Status:</b> <StatusChip component={'span'} status={ticket.status}/>
            </PropRow>
        </Grid>
        <Grid item xs={6}>
            <PropRow>
                <b> Type:</b> {types[ticket.type]}
            </PropRow>
        </Grid>
        <Grid item xs={12}>
            <PropRow>
                <b>
                    Description:
                </b>
            </PropRow>
            <PropRow>
                {ticket.description}
            </PropRow>
        </Grid>
        <Grid item container gap={2} xs={12} justifyContent={'space-between'}>
            {/*<Button size={'small'} color={'error'} endIcon={<DeleteRoundedIcon fontSize={'small'} />}>*/}
            {/*    Delete*/}
            {/*</Button>*/}
            <Button size={'small'} onClick={()=>{
                setOpenEdit(true)
            }} endIcon={<EditRoundedIcon fontSize={'small'} />}>
                Edit
            </Button>
        </Grid>
        <EditDialog
            open={openEdit}
            onClose={()=>setOpenEdit(false)}
            setEditedTicket={(ticket)=>{
                dispatch(updateTicket(ticket))
            }}
            ticket={ticket}
        />
    </Grid>
}