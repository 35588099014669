import React, {useMemo, useState} from "react";
import {
    ButtonBase,
    Box,
    Avatar,
    Grid,
    Badge,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
    alpha
} from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinRoundedIcon from '@mui/icons-material/PushPinRounded';
import ArchiveIcon from '@mui/icons-material/Archive';
import StatusChip from "../StatusChip";
import PriorityIcon from "../PriorityIcon";
import {getDiffTimeString} from "../../../Utils";
import {useNavigate} from "react-router-dom";
import paths from "../../../router/paths";
import {useSelector} from "react-redux";

function getPinnedMark(theme){
    return {
        content:'""',
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid black',
        borderBottomColor:theme.palette.success.main,
        position:'absolute',
        left:-3,
        top:0,
        transform:'rotate(-45deg)',
    }
}

const TicketPreview = React.memo( function (
    {
        badgeRead = -1,
        isYour=false,
        priority='highest',
        pinned,
        selected=false,
        ticket,
        style
    }){
    const navigate = useNavigate();
    const unread = useSelector(state => state.ticketsReducer.unreadMessages);
    const badgeContent = useMemo(()=> {
        return unread[ticket.id]||null;
    },[unread[ticket.id]]);
    const [contextMenu, setContextMenu] = React.useState(null);

    // const handleContextMenu = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setContextMenu(
    //         contextMenu === null
    //             ? {
    //                 mouseX: event.clientX + 2,
    //                 mouseY: event.clientY - 6,
    //             }
    //             : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
    //               // Other native context menus might behave different.
    //               // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
    //             null,
    //     );
    // };
    const handleClose = () => {
        setContextMenu(null);
    };
    // const [selected ,setSelected] = useState(false);
    return <div style={style}><ButtonBase
        sx={{width:'100%'}}
        // onContextMenu={handleContextMenu}
        onClick={()=>{
            navigate(paths.tickets.chat.path.replace(':id',ticket.id))
        }}
    >
        <Box 
            p={1.5} 
            flexGrow={1} 
            display={'flex'} 
            justifyContent={"flex-start"} 
            alignItems={'cneter'} 
            gap={2}
            sx={(theme)=>({
                position:'relative',
                ":before":pinned && getPinnedMark(theme),
                backgroundColor:selected?alpha(theme.palette.primary.main,.3):'initial'
            })}
        >
            <Badge 
                badgeContent={badgeContent}
                   overlap="circular" color="primary">
                <Avatar src={ticket.requester_avatar}>{ticket.requester_name[0]}</Avatar>
            </Badge>
            <Box flexGrow={1}>
                
                <Grid container justifyContent={'space-between'} flexWrap={'nowrap'} alignItems={'center'}>
                    <Grid item fontSize={14} textAlign={'left'}
                          sx={{
                              whiteSpace:'nowrap',
                              overflow:'hidden',
                              textOverflow:'ellipsis',
                              flexBasis: '100%',
                              pr:1,
                              width: '10px'
                          }}
                          flexGrow={0} flexShrink={1} fontWeight={500}>
                        #{ticket.id}  {ticket.requester_name} 
                    </Grid>
                    <Grid item textAlign={'right'} whiteSpace={"nowrap"} sx={{color:'text.disabled'}}>
                        <Tooltip title={'Updated'}>
                            <>
                            {getDiffTimeString(ticket.updated_at)} ago
                            </>
                        </Tooltip>
                        
                    </Grid>
                </Grid>
                
                <Grid container mt={1} justifyContent={'space-between'} flexWrap={'nowrap'} alignItems={'center'}>
                    <Grid
                        item 
                        fontSize={14}
                        textAlign={'left'}
                        sx={{
                            color:'text.disabled',
                            whiteSpace:'nowrap',
                            overflow:'hidden',
                            textOverflow:'ellipsis',
                            flexBasis: '100%',
                            pr:1,
                            width: '10px'
                        }} 
                        flexGrow={0}
                        flexShrink={1}
                        fontWeight={500}
                        
                    >
                        { ticket.description}  
                    </Grid>
                    <Grid
                        item 
                        textAlign={'right'}
                        display={'flex'}
                        justifyContent={'flex-end'} 
                        alignItems={'center'}
                        gap={.5}
                    >
                        <Tooltip title={`Priority: ${ticket.priority}`}>
                            <PriorityIcon priority={ticket.priority}/>
                        </Tooltip>
                        <Tooltip title={`Status: ${ticket.status}`}>
                            <StatusChip status={ticket.status}/>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </ButtonBase>
    {/*<Menu*/}
    {/*    open={contextMenu !== null}*/}
    {/*    onClose={handleClose}*/}
    {/*    anchorReference="anchorPosition"*/}
    {/*    variant={'menu'}*/}
    {/*    onClick={(e)=>e.stopPropagation()}*/}
    {/*    anchorPosition={*/}
    {/*        contextMenu !== null*/}
    {/*            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }*/}
    {/*            : undefined*/}
    {/*    }*/}
    {/*>*/}
    {/*    <MenuItem sx={{m:0,borderRadius:0}}>*/}
    {/*        <ListItemIcon>*/}
    {/*            <ArchiveIcon sx={{color:'text.primary'}} />*/}
    {/*        </ListItemIcon>*/}
    {/*        <Typography variant="inherit">*/}
    {/*            Archive*/}
    {/*        </Typography>*/}
    {/*    </MenuItem>*/}
    {/*    <MenuItem sx={{m:0,borderRadius:0}}>*/}
    {/*        <ListItemIcon>*/}
    {/*            {pinned*/}
    {/*                ?<PushPinOutlinedIcon sx={{transform: `rotate(45deg)`,color:'text.primary'}} />*/}
    {/*                :<PushPinRoundedIcon sx={{transform: `rotate(45deg)`,color:'text.primary'}}/>}*/}
    {/*        </ListItemIcon>*/}
    {/*        <Typography variant="inherit">*/}
    {/*            {pinned?'Unpin':'Pin'}*/}
    {/*        </Typography>*/}
    {/*    </MenuItem>*/}
    {/*</Menu>*/}
    </div>
});

export default TicketPreview;