import {createBrowserRouter, Outlet, redirect,} from "react-router-dom";
import Login from "../components/Login";
import {authProvider} from "../authProvider";
import store, {clearStore} from "../store";
import {setForceDark} from "../store/themeSlice";
import KanbanBoard from "../components/KanbanBoard";
import AppWrapper from "../components/AppWrapper";
import Messenger from "../components/Messenger";
import paths from "./paths";
import Profile from "../components/Profile";
import Users from "../components/Users";
import CreateUser from "../components/Users/SoloPages/Create";
import EditUser from "../components/Users/SoloPages/Edit";
import Permissions from "../components/Permissions";
import EmptyChat from "../components/Messenger/TicketChart/EmptyChat";
import TicketChart from "../components/Messenger/TicketChart";
import {Typography} from "@mui/material";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

const router = createBrowserRouter([
    {
        path:paths.root.path,
        async loader(){
            if(authProvider.isAuthenticated===undefined){
                await authProvider.checkToken();
            }
            if (!authProvider.isAuthenticated) {
                return redirect(paths.login.path);
            }
            store.dispatch(setForceDark(false));
            return null;
        },
        element:<AppWrapper><Outlet/></AppWrapper>,
        children:[
            {
                path:'*',
                element:<>
                    <Typography variant={'h3'} sx={{m:'auto',textAlign:'center',pt:6,pb:1}}>404 | Page Not Found</Typography>
                    <HeartBrokenIcon sx={{m:'auto',display:'block',fontSize:60}}/>
                </>
            },
            {
                index:true,
                Component:KanbanBoard
            },
            {
                path:paths.tickets.path,
                Component:Messenger,
                children:[
                    {
                        index:true,
                        Component:EmptyChat
                    },
                    {
                        path:paths.tickets.chat.path,
                        Component:TicketChart
                    }
                ]
            },
            {
                path:paths.profile.path,
                Component:Profile
            },
            {
                path:paths.users.create.path,
                Component:CreateUser
            },
            // {
            //     path:paths.users.edit.path,
            //     Component:EditUser
            // },
            // {
            //     path:paths.users.path,
            //     Component:Users,
            // },
            // {
            //     path:paths.permissions.path,
            //     Component:Permissions
            // }
        ]
    },
    {
        path:paths.login.path,
        loader:()=>{
            store.dispatch(setForceDark(true))
            store.dispatch(clearStore);
            return null
        },
        Component:Login
    },
    {
        path: paths.logout.path,
        action() {
            authProvider.signOut();
            return redirect(paths.login.path);
        },
        loader(){
            authProvider.signOut();
            return redirect(paths.login.path);
        }
    },
]);

export default router;