import {createSlice} from "@reduxjs/toolkit";
import sound from "../assets/notification.mp3";

const soundAlert = new Audio(sound);

export const ALLOW_SOUND = 'allowSound';

export const ticketsSlice = createSlice(
    {
        name: 'tickets',
        initialState:{
            loadingTickets:true,
            tickets:[],
            unreadMessages:{},
            forceOrderInList:{}
        },
        reducers:{
            setTickets:(state,{payload})=>{
                state.loadingTickets = false;
                state.tickets = payload;
            },
            updateTicket:(state,{payload})=>{
                state.tickets = state.tickets.map(el=>el.id===payload.id?payload:el)
            },
            addTicket:(state,{payload})=>{
                if(
                    Notification.permission==='granted'
                    && state.tickets.length
                ){
                    localStorage.getItem(ALLOW_SOUND)==='true' && soundAlert.play();
                    new Notification(`New ticket added #${payload.id}`,{
                        icon:'/logo192.png',
                        body: payload.description,
                    });
                }
                state.tickets = [...state.tickets, payload];
            },
            setUnreadMessages:(state,{payload})=>{
                state.unreadMessages = payload;
            },
            clearUnread:(state,{payload})=>{
                state.unreadMessages = {
                    ...state.unreadMessages,
                    [payload]:undefined
                }
            },
            pushTicketToTop:(state,{payload})=>{
                state.forceOrderInList = {
                    ...state.forceOrderInList,
                    ...payload
                }
            }
        }
    }
);

export const {
    setTickets,
    updateTicket,
    addTicket,
    setUnreadMessages,
    clearUnread,
    pushTicketToTop,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;