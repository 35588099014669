export default function createFilter(excludeTeams,filter){
    return el=>{
        // filter by slack space default all selected
        const team = !excludeTeams.includes(el.project_slack);
        // filter by description not case-sensitive , search fild filters by 3 fields 
        let searchText = String(el.description).toLocaleLowerCase().includes(filter.search.toLocaleLowerCase());
        // filter by requester_name 
        searchText = searchText || String(el.requester_name).toLocaleLowerCase().includes(filter.search.toLocaleLowerCase());
        // filter by id in format #id
        if(filter.search.match(/(\#|№)\d+/g)){
            searchText =  searchText || ('#'+el.id).includes(filter.search.replace(/^№/, '#'))
        }
        
        const searchPriority = filter.priority.includes(el.priority)||!filter.priority.length;
        
        const status = filter.status.includes(el.status)||!filter.status.length;

        const type = filter.type.includes(el.type)||!filter.type.length;

        return team && searchText && searchPriority && status && type;
    }
}