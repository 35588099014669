import React, {useEffect, useState} from "react";
import ToolbarLogo from "./ToolbarLogo";
import {Avatar, Box, IconButton, Toolbar,Tooltip,} from "@mui/material";
import {toggleMode} from "../../../store/themeSlice";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import NightsStayRoundedIcon from "@mui/icons-material/NightsStayRounded";
import {useDispatch, useSelector,} from "react-redux";
import ProfileMenu from "./ProfileMenu";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {addTicket, ALLOW_SOUND, setTickets, setUnreadMessages,pushTicketToTop} from "../../../store/ticketsSlice";
import {getTickets, getTypes} from "../../KanbanBoard/ticketsApi";
import sound from "../../../assets/notification.mp3";
import {useMatch} from "react-router-dom";
import paths from "../../../router/paths";
import setupSocket from "../../../router/socket";
import {authProvider} from "../../../authProvider";
import {setTypes} from "../../../store/optionsSlice";

const soundAlert = new Audio(sound);

setupSocket(authProvider.token)
export default function AppToolbar({}){
    const dispatch = useDispatch();
    const modeDark = useSelector(state=>state.themeReducer.modeDark);
    const user = useSelector(state=>state.userReducer.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notificationSound,setNotificationSound] = useState(localStorage.getItem(ALLOW_SOUND)==='true');
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    let params = useMatch(paths.tickets.chat.path);
    useEffect(()=>{
        getTickets()
            .then(response=>{
                if(Array.isArray(response.data?.data)){
                    dispatch(setTickets(response.data?.data));
                }
            });

        window.Echo.private("new_ticket").listen("NewTicketEvent", (event) => {
            if(event?.ticket) {
                dispatch(addTicket(event.ticket));
            }
        });
    
        function handleStorage (event){
            if(event.key === ALLOW_SOUND && event.type === 'storage'){
                setNotificationSound(prevState => {
                    if(event.newValue!==String(prevState)){
                        return event.newValue==="true"
                    }
                    return prevState;
                });
            }
        }
        window.addEventListener('storage', handleStorage);
        getTypes()
            .then(response=>{
                dispatch(setTypes(response.data))
            }).catch(e=>{console.log(e)})
        return ()=>{
            window.removeEventListener('storage', handleStorage);
            window.Echo.private("new_ticket").subscription.unbind_all('NewTicketEvent');
        }
    },[])
    useEffect(()=>{
        if(Number.isInteger(user?.id)){
            window.Echo.private(`unread_messages.${user.id}`).listen("UnreadEvent", (event) => {
                if(event?.data) {
                    dispatch(setUnreadMessages(event.data))
                }
            });
            window.Echo.private(`new_unread_message.${user.id}`).listen("NewUnreadMessageEvent", (event) => {
                if(event?.message) {
                    dispatch(pushTicketToTop({[event?.ticket_id]:event?.message?.ts}))
                    if(
                        Notification.permission==='granted'
                        && !(document.hasFocus()&&params?.params?.id==event?.ticket_id)
                    ){
                        localStorage.getItem(ALLOW_SOUND)==='true' && soundAlert.play();
                        new Notification(`New message in ticket #${event?.ticket_id}`,{
                            icon:'/logo192.png',
                            body: event?.message.text,
                        });
                    }
                }
            });
            return ()=>{
                window.Echo.private(`unread_messages.${user.id}`).subscription.unbind_all('UnreadEvent');
                window.Echo.private(`new_unread_message.${user.id}`).subscription.unbind_all("NewUnreadMessageEvent");
            }
        }
        return ()=>{}
    },[user])
    return <Toolbar>
        <ToolbarLogo/>
        <Box sx={{flexGrow:1}}/>
        <Tooltip title={<>Turn {notificationSound?"on":"off"} notification sound 
            <br/> {Notification.permission==='denied'?'Your browser notifications is blocked!':''}</>}>
            <IconButton
                aria-label={`Turn ${notificationSound?"on":"off"} notifications sound  `}
                onClick={()=>{
                    setNotificationSound(prevState => {
                        localStorage.setItem(ALLOW_SOUND,!prevState);
                        return !prevState;
                    });
                }}
            >
                {notificationSound?<VolumeUpIcon />:<VolumeOffIcon/>}
            </IconButton>
        </Tooltip>
        <Tooltip title="Toggle theme">
            <IconButton
                onClick={()=>{
                    dispatch(toggleMode())
                }}
            >
                {modeDark
                    ?<WbSunnyRoundedIcon color={'warning'}/>
                    :<NightsStayRoundedIcon/>
                }
            </IconButton>
        </Tooltip>
        <Tooltip title="Profile">
            <IconButton
                size={"small"}
                onClick={handleOpen}
            >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'primary.main' }}>
                    {user?.name[0]}
                </Avatar>
            </IconButton>
        </Tooltip>
        <ProfileMenu
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
        />
    </Toolbar>
}