import axios from "axios";
import {BASE_URL} from "../../router/API";
import {authProvider} from "../../authProvider";

function correctDueDate(data){
    return {
        ...data,
        due_date: data.due_date.replace('00:00:00','23:59:59')
    }
}

export const getTickets = ()=>{
   return axios({
        method:'GET',
        url:'tickets',
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
    })
       .then(response=>{
           return {
               ...response,
               data:{
                   data:Array.isArray(response.data.data)
                       ?response.data.data.map(ticket=>correctDueDate(ticket))
                       :[]
               },
           }
       })
};

export const updateTicket = (id,body)=>{
    return axios({
        method:'PUT',
        url:'tickets/'+id,
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
        data:body
    })
        .then(response=>({
            data: correctDueDate(response?.data?.data)
        }))
}

export const getTicket = (id,)=>{
    return axios({
        method:'GET',
        url:'tickets/'+id,
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
        
    })
}

export const getTypes = ()=>{
    return axios({
        method:'GET',
        url:'ticket_types',
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
    })
}